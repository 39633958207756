import React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"

const Page = ({data: {silverStripeDataObject, imageAstrid}}) => {
    const {title} = silverStripeDataObject.SilverStripeSiteTree;
    const {content, contentAfter, BioPageItems} = silverStripeDataObject.NetwerkstattBioPage;

    const  {getChildren, isLevel}  = useSiteTreeContext();
    const children = getChildren();
    const isLevel2 = isLevel(2);
    const hasSubnav = isLevel(2) || !!children.length;

    const image = imageAstrid.childImageSharp.fluid;
    const imageTitle = `“Astrid Wildner-Kerschbaumer” &copy;: Foto Furgler`;

    const sortedBioItems = BioPageItems.sort((a, b) => {
        return new Date(a.start) - new Date(b.start);
    });

    return (
        <Layout>
            <SEOTags pageTitle={title}/>

            <div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
                <div className="main">
                    {isLevel2 &&
                    <Breadcrumbs/>
                    }
                    <h1 className="font-bold text-2xl mb-4">{title}</h1>
                    <div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: content}}/>
                    <div className="my-8">
                        <h2 className="font-bold text-xl">Biografie</h2>
                        <div className="flex flex-col-reverse sm:flex-row">
                            <div className={'w-full sm:w-1/2 lg:w-2/3'}>

                            {sortedBioItems.map(({NetwerkstattBioPageItem: child}) => {
                                const start = new Date(child.start);
                                const end = new Date(child.end);

                                const formatDate = (date, format) => {

                                    const year = date.getFullYear();
                                    const month =  '0' + (date.getMonth() + 1);

                                    return format === 'yyyy' ? year : year + '/' + month.slice(-2);
                                };

                                const startString = formatDate(start, child.dateFormat);
                                const endString = formatDate(end, child.dateFormat);

                                const dateRange = (startString === endString || !child.end)
                                    ? startString
                                    : startString + ' - ' + endString;

                                return <div className={'flex my-2'}>
                                    <div className={'w-1/4'}>{dateRange}</div>
                                    <div className={'w-3/4'} ><span>{child.title}</span>
                                        {child.text &&
                                        <div className={'text-sm'} dangerouslySetInnerHTML={{__html: child.text}}/>
                                        }

                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/3 mb-4 sm:mb-0 sm:ml-4 flex flex-col justify-center">
                            <Img
                                fluid={image}
                                title={imageTitle}
                                className={'desaturated'}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: contentAfter}}/>
                </div>
            </div>
        </Layout>
    );
};

export default Page

export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: { eq: $link }) {
            SilverStripeSiteTree {
                title

            }
            NetwerkstattBioPage {
                contentAfter
                content
                BioPageItems {
                    NetwerkstattBioPageItem {
                        dateFormat
                        end
                        start
                        title
                        text
                    }
                }
            }
        }
        imageAstrid: file(
            relativePath: { regex: "/Wildner-Kerschbaumer_Astrid_0022R13x18.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 800
                    quality: 80
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }

`;